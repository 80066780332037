import service from '@/utils/service'

//获取列表
export function getSupplierList (params = {}) {
  return service({
    url: `material/supplier/get-list`,
    method: 'get',
    params
  })
}

//推送供应商价格
export function putSupplierPrice(data:any = {}) {
  return service({
    url: `material/supplier/put-price`,
    method: 'post',
    data
  })
}

//同步供应商
export function syncSupplier(params = {}) {
  return service({
    url: `material/supplier/sync`,
    method: 'get',
    params
  })
}

//编辑
export function saveSupplier(data:any = {}) {
  return service({
    url: `material/supplier/edit`,
    method: 'post',
    data
  })
}

//编辑品牌
export function editBrand(data:any = {}){
  return service({
    url: `material/supplier/edit-brand`,
    method: 'post',
    data
  })
}