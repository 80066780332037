// 设置
export function setLocalStorage(key:string, value:string) {
  return localStorage.setItem(key, value)
}

//获取指定key
export function getLocalStorage(key:string) {
  return localStorage.getItem(key)
}

// 清除指定key
export function removeLocalStorage(key:string) {
  return localStorage.removeItem(key)
}

//清除所有
export function clearLocalStorage() {
  return localStorage.clear()
}

// 版本号
export function getCurrentVersion() {
  return 'v1.04.02.10.00'
}